const TagHelpers = require("./tag-helpers")

/*
 * iterate over our jobs data set and group into different objects by different criteria (e.g., by
 * tags, or by company name)
 * - improves build time by iterating over these thousands of job posts only once (rather than doing
 *   once for tags, once for companies)
 * - keeps all of this kind of logic in one place, which at least for now makes the code more
 *   simple and consistent
 *
 */ 
module.exports = (allJobPosts) => {
    ////////////////////////////////////////////
    // build out data for tags, only used in the tags sidebar
    // Beware of Passing too much through the pageContext, since it causes a lot of memory overhead
    // and actually ends up being slower

    // set minimum amount of hits to be able to display as tags.
    // tags with less hits than this will not be displayed as sidebar, or get their own page
    const minHitsForTags = 4
    
    // this will have all tags (NOT FILTERED) and jobs array as value
    const jobsByTag = {}
    
    // this will have all companies as keys and jobs array as value
    const jobsByCompany = {}

    const jobPostsLimitedByCompany = []
    // not for company view, but for main list, limit to maxHitsForCompany jobs per company
    const maxHitsForCompany = 3
    allJobPosts.forEach(jobPost => {
        // retrieved from elasticsearch, so tags are under the _source key
        const tagsForPost = jobPost._source.tags || []
        const company = jobPost._source.company_name

        // add this job post to each tag's list
        tagsForPost.forEach((rawTag) => {
            // standardize tag to avoid dupes (e.g., two tags named Java and java) and for easy use in
            // urls. TODO better to have this kind of transformation done when adding tags in first
            const tag = TagHelpers.formatTag(rawTag)

            // set tag as key if didn't exist before
            if (!jobsByTag[tag]) {
              jobsByTag[tag] = []
            } 
            // add job post to this tag's list.
            // NOTE maintains order, which we are using at least for now
            jobsByTag[tag].push(jobPost)
        })


        // add this post to its company's list
        // first, set company as key if didn't exist before
        if (!jobsByCompany[company]) {
            jobsByCompany[company] = []
        } 

        // unless there are less than maxHitsForCompany for this company, don't add
        if (jobsByCompany[company].length < maxHitsForCompany) {
            jobPostsLimitedByCompany.push(jobPost)
        }

        // add job post to this company's list.
        // NOTE maintains order, which we are using at least for now
        jobsByCompany[company].push(jobPost)

    });
    
    // convert to arr, for faster sorting, iterating etc
    // this way we don't build way too many pages either

    // First, for tags
    // ( only want to show tags in sidebar list if they are more than 5)
    let filteredJobsByTagsArr = []
    Object.keys(jobsByTag).forEach(tag => {
      let jobs = jobsByTag[tag]
      let jobCount = jobs.length
      
      // filter by count, or if tag is blank for some reason (not sure if there are any)
      if (!tag || jobCount >= minHitsForTags) {
        // e.g., ["developer",  15]
        filteredJobsByTagsArr.push([tag, jobs.length])
      }
    })
    // sort by count
    filteredJobsByTagsArr = filteredJobsByTagsArr.sort((a, b) => b[1] - a[1]);
    
    
    Object.keys(jobsByCompany).forEach(company => {
      let jobs = jobsByCompany[company]
      let jobCount = jobs.length
    })
    
    return {filteredJobsByTagsArr, jobsByTag, jobsByCompany, jobPostsLimitedByCompany}
}
