import React from "react"
import JobPostsList from "./JobPostsList"
import JobPost from "../../dataClasses/JobPost"
import Pagination from "../../components/Pagination/Pagination"
import { Grid } from "@mui/material"

function JobsPostsColumn({ jobPostsList, pageContext }) {
  const jobPosts = jobPostsList.map(
    (rawJobPostData) => new JobPost(rawJobPostData)
  )

  // could also just replace, since they should be the same
  const paginationInfo = {
    pageNumber: pageContext.currentPage,
    total: pageContext.totalCount,
    limit: pageContext.limit,
    linkSchema: pageContext.url,
  }

  return (
    <>
      <Grid container spacing={2} className="jobPostsColumnContainer">
        <JobPostsList jobPosts={jobPosts} />
      </Grid>
      <Pagination {...paginationInfo} />
    </>
  )
}

export default JobsPostsColumn
