import { Grid, Typography } from "@mui/material"
import React from "react"
import CustomButton from "../UI/CustomButton/CustomButton"
import "./banner.scss"

const Banner = ({
  bannerClass,
  titleClass,
  bannerTitle,
  buttonClass,
  buttonText,
  buttonLink,
  target,
}) => {
  return (
    <Grid className={`bannerContainer ${bannerClass}`}>
      <Grid>
        <Typography
          className={` bannerHeading ${titleClass}`}
          variant="h1"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: bannerTitle }}
        />
      </Grid>
      <Grid className="linkButtonContainer">
        {buttonText && (
          <CustomButton
            buttonClass={buttonClass}
            buttonText={buttonText}
            buttonLink={buttonLink}
            target={target}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Banner
