import React from "react"
import { Link } from "gatsby"
import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight"
import "./customButton.scss"

const CustomButton = ({ buttonClass, buttonText, buttonLink, target }) => {
  return (
    <>
      {target ? (
        <a
          href={buttonLink}
          target={target}
          className={`linkButton ${buttonClass}`}
        >
          {buttonText}
          <HiOutlineArrowNarrowRight />
        </a>
      ) : (
        <Link to={buttonLink} className={`linkButton ${buttonClass}`}>
          {buttonText}
          <HiOutlineArrowNarrowRight />
        </Link>
      )}
    </>
  )
}

export default CustomButton
