import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import JobPostsColumn from "../components/jobPosts/JobPostsColumn"
import categorizeJobPosts from "../utils/categorizeJobPosts"
import RotatingAds from "../components/RotatingAds/RotatingAds"
import "./style/column.scss"
import { Box, Grid } from "@mui/material"
import PageTitle from "../components/PageTitle/PageTitle"
import { Helmet } from "react-helmet"

function JobsPage({ data }) {
  const allJobPosts = data.jobPostsList.nodes[0].posts

  const { jobPostsLimitedByCompany } = categorizeJobPosts(allJobPosts)

  const newTags = allJobPosts.map((job) => {
    return job._source.tags.map((tag) => tag)
  })

  const itemCountNew = newTags.reduce(function (obj, v) {
    for (let i = 0; i < v.length; i++) {
      obj[v[i]] = (obj[v[i]] || 0) + 1
    }
    return obj
  }, {})
  let entries = Object.entries(itemCountNew)
  let sorted = entries.sort((a, b) => b[1] - a[1]).filter((x) => x[1] >= 1)
  // mocking what other pages will receive from Gatsby's createPage call, but only what we need for our pagination component
  const PAGE_SIZE = 12
  const index = 0 // first page
  // don't use all job posts, limit by company (so don't get too many by a single company)
  const jobPostsList = jobPostsLimitedByCompany.slice(0, PAGE_SIZE)

  // make sure to set totalCount from JobPostsColumn component
  const paginationContext = {
    first: PAGE_SIZE / 2,
    skip: PAGE_SIZE * index,
    limit: PAGE_SIZE,
    pageNumber: index + 1,
    hasNextPage: true, // TODO be careful, what if we only have less than 12 job posts?
    hasPreviousPage: false,
    nextPageLink: `/jobs/page/${index + 2}`,
    currentPage: index + 1,
    linkSchema: `/jobs/page`,
    // I think redundant with linkSchema, But whatever
    url: `/jobs/page`,
    // We limit the number of job posts created by an individual company to 3, so total pages created is different from total available nodes.
    totalCount: jobPostsLimitedByCompany.length,
  }

  const checkId = jobPostsList.map((id) => id._id)

  return (
    <Layout>
      <Helmet>
        <title>Jobs - Your Source for Job Opportunities</title>
        <meta
          name="description"
          content="Find the latest job opportunities at Jobs - Your Source for Job Opportunities"
        />
        <meta
          property="og:title"
          content="Jobs - Your Source for Job Opportunities"
        />
        <meta
          property="og:description"
          content="Find the latest job opportunities at Jobs - Your Source for Job Opportunities"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra Link - Your Source for Cassandra Resources",
            keywords: "Cassandra Link, Cassandra Resources, Jobs, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta property="og:title" content={"Cassandra Link"} />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Cassandra Link"} />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/jobs" />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta
          name="twitter:title"
          content="Jobs - Your Source for Job Opportunities"
        />
        <meta
          name="twitter:description"
          content="Find the latest job opportunities at Jobs - Your Source for Job Opportunities"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
      </Helmet>
      <PageTitle title="Cassandra Job Listings" />
      <Box
        className="articlesContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          marginX: "auto",
        }}
      >
        <Grid container maxWidth={"xl"} sx={{ mx: "auto" }} spacing={2}>
          <Grid item xs={12} className="column center-column">
            <Grid>
              {`${checkId}` ? (
                <JobPostsColumn
                  jobPostsList={jobPostsList}
                  pageContext={paginationContext}
                />
              ) : (
                "No job posts"
              )}
            </Grid>
          </Grid>
        </Grid>

        <Box
          className="adsContainer"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: 1600,
            marginX: "auto",
            marginTop: "100px",
          }}
        >
          <Grid container spacing={1}>
            <RotatingAds />
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default JobsPage

// - can keep this identical with whatever is in jobPostsTemplate.js (if we use query and not just
// pageContext in jobPostsTemplate.js)
// - comment out fields we don't need, to help keep query run time and also js file size low
export const pageQuery = graphql`
  query JobsIndexQuery($skip: Int) {
    jobPostsList: allCassandraJobPosts(
      filter: {
        posts: {
          elemMatch: { _source: { original_post_url: { ne: null } }, _id: {} }
        }
      }
      skip: $skip
    ) {
      totalCount
      nodes {
        posts {
          _id
          _source {
            external_api_name
            external_api_id
            original_post_url
            tags
            external_api_published_at
            #description
            description_html
            position_name
            position_category
            company_name
            location
          }
        }
      }
    }
  }
`
