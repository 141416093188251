import React from "react"
import _ from "lodash"
import { Grid, Typography } from "@mui/material"
import NewsLetterBox from "../NewsLetter/NewsLetterBox"
import Banner from "../Banner/Banner"

const RotatingAds = () => {
  const topAds = [
    <Grid
      item
      xs={12}
      sm={6}
      md={2.4}
      className="sidebar-item"
      key="training-cassandra"
    >
      <Banner
        bannerClass="cassandraTraningContainer"
        bannerTitle="Need Cassandra <span>Training?</span>"
        titleClass="cassandraTraningHeading"
        buttonClass="linkButtonCassandraTraning"
        buttonText="Get Started"
        buttonLink="/training-cassandra"
      />
    </Grid>,
  ]

  const ads = [
    <Grid
      item
      xs={12}
      sm={6}
      md={2.4}
      className="sidebar-item"
      key="cassandra-tools"
    >
      <Banner
        bannerClass="cassandraToolsContainer"
        bannerTitle="Collection of Apache <span>Cassandra Tools</span>"
        titleClass="cassandraToolsHeading"
        buttonClass="linkButtonCassandraTools"
        buttonText="Open Tools"
        buttonLink="/cassandra.toolkit"
      />
    </Grid>,
    <Grid item xs={12} sm={6} md={2.4} className="sidebar-item" key="runbook">
      <Banner
        bannerClass="runBookContainer"
        bannerTitle="GET ANANT'S FREE APACHE CASSANDRA RUNBOOK"
        titleClass="runBookHeading"
        buttonClass="linkButtonRunBook"
        buttonText="Get Runbook"
        buttonLink="https://blog.anant.us/apache-cassandra-runbook"
        target="_blank"
      />
    </Grid>,
    <Grid
      item
      xs={12}
      sm={6}
      md={2.4}
      className="sidebar-item"
      key="newsletter-box"
    >
      <NewsLetterBox />
    </Grid>,
    <Grid
      item
      xs={12}
      sm={6}
      md={2.4}
      className="sidebar-item"
      key="contribute"
    >
      <Banner
        bannerClass="contributeContainer"
        bannerTitle="Be part of the Apache <span>cassandra</span> community."
        titleClass="contributeHeading"
        buttonClass="linkButtonContribute"
        buttonText="Contribute"
        buttonLink="https://github.com/Anant/awesome-cassandra"
        target="_blank"
      />
    </Grid>,
  ]

  const _ = require("lodash")
  const shuffledArray = _.shuffle(ads)

  return [topAds, shuffledArray]
}

export default RotatingAds
