import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { CheckCircle } from "react-feather"
import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight"
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Button,
  Typography,
} from "@mui/material"
import "./newsLetterBox.scss"

const NewsLetterBox = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [sendButtonMsg, setSendButtonMsg] = useState("Subscribe")
  const [newsletterSuccessMsg, setNewsletterSuccessMsg] = useState("")
  const [disabled, disableButton] = useState(false)

  const _handleSubmit = async (e) => {
    e.preventDefault()

    const res = await addToMailchimp(email, { NAME: name })
    if (res.result === "error") {
      setNewsletterSuccessMsg(`${email} is already subscribed to list.`)
    } else {
      setNewsletterSuccessMsg("Subscription Successful. Thanks!")
      setSendButtonMsg("Subscribed")
      setName("")
      setEmail("")
      disableButton(true)
    }

    setTimeout(() => {
      setNewsletterSuccessMsg("")
      setName("")
      setEmail("")
      setSendButtonMsg("Subscribe")
      disableButton(false)
    }, 5000)
  }

  const emailHandle = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  const nameHandle = (e) => {
    e.preventDefault()
    setName(e.target.value)
  }

  return (
    <Grid className="newsletter-box">
      <Grid className="newsletterTitleContainer">
        <Typography variant="h1" className="title">
          Become an Expert in <span className="bold">Cassandra</span> with our{" "}
          <span className="bold">NEWSLETTER</span>
        </Typography>
      </Grid>

      <Box component="form" onSubmit={_handleSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            className="formGroup"
            // controlId="validationCustom01"
          >
            <InputLabel className="formLabel">Full Name</InputLabel>
            <TextField
              className="newsLetterInput"
              size="small"
              required
              type="text"
              value={name}
              onChange={nameHandle}
              placeholder="Michael Scott"
            />
          </Grid>
          <Grid
            item
            xs={12}
            className="formGroup"
            // controlId="formBasicEmail"
          >
            <InputLabel className="formLabel">Email</InputLabel>
            <TextField
              className="newsLetterInput"
              size="small"
              required
              type="email"
              value={email}
              onChange={emailHandle}
              placeholder="michael.s@gmail.com"
            />
          </Grid>
          <Grid className="newsLetterBtnContainer">
            <Button
              id="newsletter"
              disabled={disabled}
              variant="primary"
              type="submit"
            >
              {sendButtonMsg}
              <HiOutlineArrowNarrowRight />
            </Button>
          </Grid>
          <Grid className="warning-success">
            {newsletterSuccessMsg !== "" && <CheckCircle size={13} />}{" "}
            {newsletterSuccessMsg}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default NewsLetterBox
