import React from "react"
import { Link } from "gatsby"
import _ from "lodash"
import TagHelpers from "../../utils/tag-helpers"
import { FaRegCalendarAlt } from "@react-icons/all-files/fa/FaRegCalendarAlt"

import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material"

// NOTE In reality is just a jobs post list, whether or not they are featured.
// But keeping the "Featured" prefix for the component name to be consistent with featuredArticles.js
function JobPostsList({ jobPosts }) {
  //====================================================/
  //Filters articles for duplicate values
  // TODO move this to our processing step in our data pipeline.
  // Not urgent though, Doesn't slow things down for much at all

  const filteredJobPosts = jobPosts.filter((jobPost, index) => {
    const matchIndex = jobPosts.findIndex((firstMatch) => {
      return firstMatch.original_post_url === jobPost.original_post_url
    })

    // if it is not the first match with this url, don't allow
    return matchIndex === index
  })

  //====================================================/
  return (
    <>
      {filteredJobPosts.map((jobPost, index) => {
        const tagsToShow = (jobPost.tags || []).slice(0, 5)
        return (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card className="jobCardContainer">
              <CardContent className="jobCardContentContainer">
                {jobPost.getJobLogo() && (
                  <Link to={`/jobs/${jobPost.slug}`}>
                    <Grid item md={12} className="imageContainer">
                      <img className="cardImage" src={jobPost.getJobLogo()} />
                    </Grid>
                  </Link>
                )}
                <Link to={`/jobs/${jobPost.slug}`}>
                  <Grid className="cardHeader">
                    <Typography variant="h1" className="title">
                      {jobPost.position_name.length > 80
                        ? jobPost.position_name.substring(0, 80) + "..."
                        : jobPost.position_name}
                    </Typography>

                    {jobPost.external_api_published_at && (
                      <Typography className="dateTime">
                        <FaRegCalendarAlt
                          fontSize="small"
                          className="dateTimeIcon"
                        />
                        {new Date(
                          jobPost.external_api_published_at
                        ).toLocaleDateString("en-US")}
                      </Typography>
                    )}
                  </Grid>
                </Link>
                <Grid container className="cardContentContainer">
                  <Grid item xs={12} className="descriptionContainer">
                    <Link to={`/jobs/${jobPost.slug}`}>
                      <Grid
                        dangerouslySetInnerHTML={{
                          __html: jobPost.description_html,
                        }}
                        className="cardDescription"
                      />
                      <Divider className="cardDivider" />
                    </Link>
                    <Grid className="tagContainer">
                      {jobPost.tags &&
                        tagsToShow.map((rawTag) => {
                          const tag = TagHelpers.formatTag(rawTag)
                          return (
                            <Chip
                              label={tag}
                              key={tag}
                              component="a"
                              size="small"
                              className="cardTag"
                              href={`/jobs-tags/${tag.replace(
                                /[^a-zA-Z]/g,
                                "-"
                              )}/1`}
                            />
                          )
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </>
  )
}

export default JobPostsList
