import DOMPurify from "dompurify"
import marked from "marked"

marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function(code, language) {
    const hljs = require("highlight.js")
    const validLanguage = hljs.getLanguage(language) ? language : "plaintext"
    return hljs.highlight(validLanguage, code).value
  },
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  sanitizer: DOMPurify.sanitize,
  smartLists: true,
  smartypants: false,
  xhtml: false,
})

// Maps fields from jobPostObj._source to obj. E.g., jobPost.external_api_name = jobPost._source.external_api_name
const fields = [
  "external_api_name",
  "external_api_id",
  "original_post_url",
  "tags",
  "external_api_published_at",
  "description",
  "description_html",
  "position_name",
  "position_category",
  "company_name",
  "company_logo_url",
  "external_api_verified",
  "external_api_original",
  "job_post_image_url",
  "location",
]

export default class JobPost {
  // takes what we get from ES, but processed by gatsby/graphql
  // TODO need to make it possible to instantiate from algolia also
  constructor(jobPostObj, source = "elasticsearch") {
    if (source == "elasticsearch") {
      this.id = jobPostObj._id

      fields.forEach(field => {
        this[field] = jobPostObj._source[field]
      })
    } else if (source == "algolia") {
      this.id = "" // not yet setting. can just use slug (?)

      // just a simple object
      fields.forEach(field => {
        this[field] = jobPostObj[field]
      })
    }

    // pretty hacky, but necessary for some of these RemoteOK job posts.
    // They have a bullet or something like that, removecs it
    // see for example RemoteOK-97160 at
    // https://remoteok.io/remote-jobs/97160-remote-software-engineer-o-spanspan-classone-liner
    if (this.company_name.includes('â\x80¢ </span><span class="one-liner')) {
      this.company_name = ""
    }

    // make slug based on primary keys. Using Elassandra's _id for now which does it for us
    // should keep in sync with whatever is in gatsby-node though for creatingPages
    this.slug = encodeURIComponent(
      `${this.external_api_name}-${this.external_api_id}`
    )
    this.showViewUrl = `/jobs/${this.slug}`
  }

  getJobLogo() {
    // for the most Park, would want to prioritize the specific job post image.
    // However, remoteok job_post_image_url are 404ing on us, so do company logo instead for those if we want to get those from the json api.
    // in the meantime, just show no image

    if (this.external_api_name == "RemoteOK") {
      return this.company_logo_url || null
    } else {
      return this.job_post_image_url || this.company_logo_url
    }
  }

  getCleanDescriptionHTML() {
    // current implementation, we don't know if it's markdown or HTML.
    const raw = this.description_html

    // make utf-8 (gets emojis and apostrophes and stuff looking right)
    const encoded = decodeURIComponent(encodeURIComponent(raw))

    // RemoteOK in particular needs these...TODO find a better way, that catches more flexible.
    // - Might end up moving this back into python processing job, though not significantly
    // slowing things down currently
    // - NOTE you can get these strings from CQLSH. Doesn't work if try to replace using regex
    // copied from browser console
    // issues
    // - was just using escape(), which works well but is deprecated and throws errors on other
    // strings
    const escaped = encoded
      .replace(/â\x80\x98/g, "'") // fix open single quote e.g., RemoteOK-86779
      .replace(/â\x80\x99/g, "'") // fix apostrophes e.g., RemoteOK-86779
      .replace(/â\x80\x9b/g, "'") // fix close single quote
      .replace(/â\x80\x94/g, "&mdash;") // for e.g., RemoteOK-97156 emdash
      .replace(/â\x80\x9c/g, '"') // for e.g., RemoteOK-97156 open quotes
      .replace(/â\x80\x9d/g, '"') // for e.g., RemoteOK-97156 end quotes
      .replace(/â\x80\x9a/g, ",") // for e.g., RemoteOK-97156 comma
      .replace(/â\x80¢/g, "-") // for e.g., RemoteOK-86779 bullet (?)

    const parsedDescriptionHTML = marked(escaped)

    return parsedDescriptionHTML
  }
}
